import DATA_MODEL_TABLE from "./utils/constants/dataModelTable";

export const INSTRUMENT_REPOSITORY_ADMIN = "InstrumentRepositoryAdmin";
export const INSTRUMENT_REPOSITORY_USER = "InstrumentRepositoryUser";
export const INSTRUMENT_REPOSITORY_VIEWER = "InstrumentRepositoryViewer";

export const GROUPS_TOKEN_PATH = "signInUserSession.accessToken.payload.cognito:groups";

export const DEFAULT_SITE_NAME = "Pleasanton";
export const DEFAULT_SITE_TIMEZONE = "Europe/Zurich";
export const OTHER_REASON = "Other reason";

export const OTHER = "Other";

export const ACCESS_TOKEN_PATH = "signInUserSession.idToken.payload";
export const SYNC_PROFILE_FIELDS = {
  givenName: "custom:firstName",
  familyName: "custom:lastName",
  name: "preferred_username",
  phone: "custom:phoneNumber",
  site: "custom:site"
};

export const EDIT_REASON = {
  INHERITED_BY_MASTER_DATA_CHANGE: "Inherited by master data change"
};

export const equipmentStatus = {
  active: { key: "ACTIVE", value: "Active" },
  pending: { key: "PENDING", value: "Pending" },
  deleted: { key: "DELETED", value: "Deleted" }
};

export const statusChip = {
  ACTIVE: equipmentStatus?.active?.value,
  PENDING: equipmentStatus?.pending?.value,
  DELETED: equipmentStatus?.deleted?.value
};

export const SPLITTER = {
  AND: " AND ",
  OR: " OR "
};

export const getSteps = () => {
  return [
    {
      id: 0,
      name: "Basic identification",
      error: false,
      valid: false,
      completed: false,
      active: true,
      tooltip: "Step 1: Definition of basic identification for the equipment"
    },
    {
      id: 1,
      name: "Equipment description",
      error: false,
      valid: false,
      completed: false,
      active: false,
      tooltip: "Step 2: Definition of basic description for the equipment"
    },
    {
      id: 2,
      name: "Laboratory and responsibility",
      error: false,
      valid: false,
      completed: false,
      active: false,
      tooltip: "Step 3: Definition of physical location and responsibility for the equipment"
    },
    {
      id: 3,
      name: "Qualification status and maintenance",
      error: false,
      valid: false,
      completed: false,
      active: false,
      tooltip: "Step 4: Definition of Qualification status and maintenance information for the equipment"
    },
    {
      id: 4,
      name: "Configuration and availability",
      error: false,
      valid: false,
      completed: false,
      active: false,
      tooltip: "Step 5: Definition of the configuration, availability and booking check in for the equipment"
    }
  ];
};

export const emptyLinkedInstanceStep = {
  linkedInstance: null,
  secondaryID: "",
  serialNumber: "",
  materialNumber: "",
  equipmentId: ""
};
export const emptyLinkedInstanceEdit = {
  id: null,
  displayImage: null,
  [DATA_MODEL_TABLE?.linkedInstance?.key]: null,
  [DATA_MODEL_TABLE?.serialNumber?.key]: "",
  [DATA_MODEL_TABLE?.materialNumber?.key]: "",
  [DATA_MODEL_TABLE?.equipmentId?.key]: "",
  [DATA_MODEL_TABLE?.manufacturer?.key]: "",
  [DATA_MODEL_TABLE?.equipmentModel?.key]: null,
  [DATA_MODEL_TABLE?.equipmentCategory?.key]: null,
  [DATA_MODEL_TABLE?.variant?.key]: null,
  [DATA_MODEL_TABLE?.editReason?.key]: null,
  [DATA_MODEL_TABLE?.editComment?.key]: ""
};

export const emptyInstrumentIdentification = {
  siteName: null,
  serialNumber: null,
  materialNumber: null,
  inventoryNumber: null,
  displayImage: null,
  equipmentId: null,
  instrumentRUDI: null,
  instrumentGTIN: null
};

export const GXPemptyInstruments = {
  sop: [],
  csv: null,
  electronicRecord: null,
  electronicSignatures: null,
  dateOfNextPeriodicReview: null,
  createdAt: null,
  updatedAt: null,
  maintenancePlan: "",
  gxpRelevant: null,
  qualificationStatus: null,
  controlledEquipmentStatus: null,
  dateOfNextMaintanance: null,
  dateOfLastMaintanance: null,
  maintenanceIntervalInDays: "",
  documentedMaintenanceDate: "",
  qualificationDocuments: {
    isSynchronized: false,
    value: []
  },
  systemStatus: null
};

export const GXPemptyInstrumentsOptions = {
  csv: [
    { key: "NA", value: "NA" },
    { key: "No", value: "No" },
    { key: "Yes", value: "Yes" }
  ],
  electronicRecord: [
    { key: "NA", value: "NA" },
    { key: "No", value: "No" },
    { key: "Yes", value: "Yes" }
  ],
  electronicSignatures: [
    { key: "NA", value: "NA" },
    { key: "No", value: "No" },
    { key: "Yes", value: "Yes" }
  ],
  gxpRelevant: [
    { key: "No", value: "No" },
    { key: "Yes", value: "Yes" }
  ],
  qualificationStatus: [
    {
      key: "CHANGED_COMPONENT_ONLY",
      value: "Changed component only"
    },
    { key: "NA", value: "NA" },
    { key: "NOT_QUALIFIED", value: "Not qualified" },
    { key: "QUALIFIED", value: "Qualified" }
  ],
  controlledEquipmentStatus: [
    { key: "CALIBRATED", value: "Calibrated" },
    { key: "NA", value: "NA" },
    { key: "NOT_CALIBRATED", value: "Not calibrated" }
  ],
  dateOfNextMaintanance: null,
  dateOfLastMaintanance: null
};

export const emptyEquipmentDescription = {
  variant: "",
  comment: null,
  cluster: null,
  equipmentNickName: null,
  tags: []
};

export const emptyAnalyzerConfiguration = {
  softwareVersion: null,
  configurationBaseline: null,
  installedTests: [],
  isBookable: false,
  isVisualized: false
};
export const emptyLaboratoryInfo = {
  belongingToGroup: null,
  responsiblePerson: null,
  responsibleProxy: null,
  systemOwner: null,
  buildingLocation: null,
  floor: null,
  room: null,
  functionalLocation: null,
  costCenter: null
};

export const emptyDigitalLabInfo = {
  isBookable: false,
  isVisualized: false
};

export const allTableColumn = () => [
  {
    key: DATA_MODEL_TABLE.equipmentModel.key,
    val: DATA_MODEL_TABLE.equipmentModel.value,
    order: 1,
    show: false,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.serialNumber.key,
    val: DATA_MODEL_TABLE.serialNumber.value,
    order: 2,
    show: true,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.materialNumber.key,
    val: DATA_MODEL_TABLE.materialNumber.value,
    order: 3,
    show: true,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.siteName.key,
    val: DATA_MODEL_TABLE.siteName.value,
    order: 4,
    show: true,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.manufacturer.key,
    val: DATA_MODEL_TABLE.manufacturer.value,
    order: 5,
    show: true,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.instrumentGTIN.key,
    val: "GTIN number",
    order: 6,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.equipmentId.key,
    val: DATA_MODEL_TABLE.equipmentId.value,
    order: 7,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.location.key,
    val: DATA_MODEL_TABLE.location.value,
    order: 8,
    show: false,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.responsiblePerson.key,
    val: DATA_MODEL_TABLE.responsiblePerson.value,
    order: 9,
    show: false,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.isBookable.key,
    val: DATA_MODEL_TABLE.isBookable.value,
    order: 10,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.instrumentRUDI.key,
    val: "RUDI number",
    order: 11,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.equipmentCategory.key,
    val: DATA_MODEL_TABLE.equipmentCategory.value,
    order: 12,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.comment.key,
    val: DATA_MODEL_TABLE.comment.value,
    order: 13,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.belongingToGroup.key,
    val: DATA_MODEL_TABLE.belongingToGroup.value,
    order: 14,
    show: false,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.room.key,
    val: DATA_MODEL_TABLE.room.value,
    order: 15,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.systemOwner.key,
    val: DATA_MODEL_TABLE.systemOwner.value,
    order: 16,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.responsibleProxy.key,
    val: DATA_MODEL_TABLE.responsibleProxy.value,
    order: 17,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.sop.key,
    val: DATA_MODEL_TABLE.sop.value,
    order: 18,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.csv.key,
    val: DATA_MODEL_TABLE.csv.value,
    order: 19,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.electronicRecord.key,
    val: DATA_MODEL_TABLE.electronicRecord.value,
    order: 20,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.electronicSignatures.key,
    val: DATA_MODEL_TABLE.electronicSignatures.value,
    order: 21,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.dateOfNextPeriodicReview.key,
    val: DATA_MODEL_TABLE.dateOfNextPeriodicReview.value,
    order: 22,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.maintenancePlan.key,
    val: DATA_MODEL_TABLE.maintenancePlan.value,
    order: 23,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.gxpRelevant.key,
    val: DATA_MODEL_TABLE.gxpRelevant.value,
    order: 24,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.qualificationStatus.key,
    val: DATA_MODEL_TABLE.qualificationStatus.value,
    order: 25,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.dateOfNextMaintanance.key,
    val: DATA_MODEL_TABLE.dateOfNextMaintanance.value,
    order: 26,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.softwareVersion.key,
    val: "Version",
    order: 27,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.configurationBaseline.key,
    val: DATA_MODEL_TABLE.configurationBaseline.value,
    order: 28,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.installedTests.key,
    val: DATA_MODEL_TABLE.installedTests.value,
    order: 29,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.qualificationDocuments.key,
    val: DATA_MODEL_TABLE.qualificationDocuments.value,
    order: 30,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.isVisualized.key,
    val: DATA_MODEL_TABLE.isVisualized.value,
    order: 31,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.dateOfLastMaintanance.key,
    val: DATA_MODEL_TABLE.dateOfLastMaintanance.value,
    order: 32,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.buildingLocation.key,
    val: DATA_MODEL_TABLE.buildingLocation.value,
    order: 33,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.floor.key,
    val: DATA_MODEL_TABLE.floor.value,
    order: 34,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.cluster.key,
    val: DATA_MODEL_TABLE.cluster.value,
    order: 35,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.equipmentAdministrator.key,
    val: DATA_MODEL_TABLE.equipmentAdministrator.value,
    order: 36,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.equipmentNickName.key,
    val: DATA_MODEL_TABLE.equipmentNickName.value,
    order: 37,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.functionalLocation.key,
    val: DATA_MODEL_TABLE.functionalLocation.value,
    order: 38,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.costCenter.key,
    val: DATA_MODEL_TABLE.costCenter.value,
    order: 39,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.inventoryNumber.key,
    val: DATA_MODEL_TABLE.inventoryNumber.value,
    order: 40,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.variant.key,
    val: DATA_MODEL_TABLE.variant.value,
    order: 41,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.documentedMaintenanceDate.key,
    val: DATA_MODEL_TABLE.documentedMaintenanceDate.value,
    order: 42,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.controlledEquipmentStatus.key,
    val: DATA_MODEL_TABLE.controlledEquipmentStatus.value,
    order: 43,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.maintenanceIntervalInDays.key,
    val: DATA_MODEL_TABLE.maintenanceIntervalInDays.value,
    order: 44,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.weightInkg.key,
    val: DATA_MODEL_TABLE.weightInkg.value,
    order: 45,
    show: false,
    sortStatus: false,
    imperialField: DATA_MODEL_TABLE?.weightInlbs?.key
  },
  {
    key: DATA_MODEL_TABLE.heightInmm.key,
    val: DATA_MODEL_TABLE.heightInmm.value,
    order: 46,
    show: false,
    sortStatus: false,
    imperialField: DATA_MODEL_TABLE?.heightIninch?.key
  },
  {
    key: DATA_MODEL_TABLE.lengthInmm.key,
    val: DATA_MODEL_TABLE.lengthInmm.value,
    order: 47,
    show: false,
    sortStatus: false,
    imperialField: DATA_MODEL_TABLE?.lengthIninch?.key
  },
  {
    key: DATA_MODEL_TABLE.depthInmm.key,
    val: DATA_MODEL_TABLE.depthInmm.value,
    order: 48,
    show: false,
    sortStatus: false,
    imperialField: DATA_MODEL_TABLE?.depthIninch?.key
  },
  {
    key: DATA_MODEL_TABLE.clearanceLeftInmm.key,
    val: DATA_MODEL_TABLE.clearanceLeftInmm.value,
    order: 49,
    show: false,
    sortStatus: false,
    imperialField: DATA_MODEL_TABLE?.clearanceLeftIninch?.key
  },
  {
    key: DATA_MODEL_TABLE.clearanceRightInmm.key,
    val: DATA_MODEL_TABLE.clearanceRightInmm.value,
    order: 50,
    show: false,
    sortStatus: false,
    imperialField: DATA_MODEL_TABLE?.clearanceRightIninch?.key
  },
  {
    key: DATA_MODEL_TABLE.clearanceHeightInmm.key,
    val: DATA_MODEL_TABLE.clearanceHeightInmm.value,
    order: 51,
    show: false,
    sortStatus: false,
    imperialField: DATA_MODEL_TABLE?.clearanceHeightIninch?.key
  },
  {
    key: DATA_MODEL_TABLE.clearanceToFrontInmm.key,
    val: DATA_MODEL_TABLE.clearanceToFrontInmm.value,
    order: 52,
    show: false,
    sortStatus: false,
    imperialField: DATA_MODEL_TABLE?.clearanceToFrontIninch?.key
  },
  {
    key: DATA_MODEL_TABLE.clearanceBackwardsInmm.key,
    val: DATA_MODEL_TABLE.clearanceBackwardsInmm.value,
    order: 53,
    show: false,
    sortStatus: false,
    imperialField: DATA_MODEL_TABLE?.clearanceBackwardsIninch?.key
  },
  {
    key: DATA_MODEL_TABLE.tags.key,
    val: DATA_MODEL_TABLE.tags.value,
    order: 54,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.linkedInstance.key,
    val: DATA_MODEL_TABLE.linkedInstance.value,
    order: 55,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.systemStatus.key,
    val: DATA_MODEL_TABLE.systemStatus.value,
    order: 56,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.heatOutputInW.key,
    val: DATA_MODEL_TABLE.heatOutputInW.value,
    order: 57,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.pressureEquipment.key,
    val: DATA_MODEL_TABLE.pressureEquipment.value,
    order: 58,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.noiseIndBA.key,
    val: DATA_MODEL_TABLE.noiseIndBA.value,
    order: 59,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.noiseSource.key,
    val: DATA_MODEL_TABLE.noiseSource.value,
    order: 60,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.noiseSourceDetails.key,
    val: DATA_MODEL_TABLE.noiseSourceDetails.value,
    order: 61,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.positioningNote.key,
    val: DATA_MODEL_TABLE.positioningNote.value,
    order: 62,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.positioning.key,
    val: DATA_MODEL_TABLE.positioning.value,
    order: 63,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.vibrationSensitive.key,
    val: DATA_MODEL_TABLE.vibrationSensitive.value,
    order: 64,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.status.key,
    val: DATA_MODEL_TABLE.status.value,
    order: 65,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.createdAt.key,
    val: DATA_MODEL_TABLE.createdAt.value,
    order: 66,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.updatedAt.key,
    val: DATA_MODEL_TABLE.updatedAt.value,
    order: 67,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.network.key,
    val: DATA_MODEL_TABLE.network.value,
    order: 69,
    show: false,
    sortStatus: false
  },
  {
    val: DATA_MODEL_TABLE.checkInRequired.value,
    key: DATA_MODEL_TABLE.checkInRequired.key,
    order: 70,
    show: false,
    sortStatus: false
  },
  {
    val: DATA_MODEL_TABLE.checkInStartPeriod.value,
    key: DATA_MODEL_TABLE.checkInStartPeriod.key,
    order: 71,
    show: false,
    sortStatus: false
  },
  {
    val: DATA_MODEL_TABLE.checkInGracePeriod.value,
    key: DATA_MODEL_TABLE.checkInGracePeriod.key,
    order: 72,
    show: false,
    sortStatus: false
  },
  {
    val: DATA_MODEL_TABLE.configurationDetails.value,
    key: DATA_MODEL_TABLE.configurationDetails.key,
    order: 73,
    show: false,
    sortStatus: false
  }
];

export const filterFields = [
  DATA_MODEL_TABLE?.belongingToGroup?.key,
  DATA_MODEL_TABLE?.dateOfNextMaintanance?.key,
  DATA_MODEL_TABLE?.equipmentAdministrator?.key,
  DATA_MODEL_TABLE?.equipmentCategory?.key,
  DATA_MODEL_TABLE?.manufacturer?.key,
  DATA_MODEL_TABLE?.responsiblePerson?.key,
  DATA_MODEL_TABLE?.siteName?.key
];

export const START_DATE = "Start date";
export const END_DATE = "End date";
export const MORE_DETAILS = "More details";
export const SHOW_HIERARCHY = "Show hierarchy";

export const DEFAULT_LIMIT = 5;
export const DEFAULT_LIMIT_FOR_LOG = 10;

export const AUDIT_TRAIL_LIMIT = 100;
export const CLUSTER_SUB_EQUIPMENT_MAX_SELECTED_LIMIT = 50;
export const CLUSTER_SUB_EQUIPMENT_MIN_SELECTED_LIMIT = 2;

export const REMOVE_OTHER_FIELDS = [
  "buildingLocation",
  "floor",
  "room",
  "entryType",
  "subEquipment",
  "clusterId",
  "dateOfNextMaintanance",
  "comment",
  "linkedInstanceRef",
  "linkedInstanceDisplay",
  "leadingSoftwareVersionId",
  "configurationBaseline",
  "configurationDetails",
  "controlledEquipmentStatus",
  "costCenter",
  "csv",
  "dateOfLastMaintanance",
  "dateOfNextPeriodicReview",
  "createdAt",
  "updatedAt",
  "displayImage",
  "documentedMaintenanceDate",
  "electronicRecord",
  "electronicSignatures",
  "equipmentId",
  "functionalLocation",
  "installedTests",
  "instrumentGTIN",
  "instrumentRUDI",
  "inventoryNumber",
  "location",
  "maintenanceIntervalInDays",
  "maintenancePlan",
  "manufacturer",
  "materialNumber",
  "serialNumber",
  "sop",
  "status",
  "systemOwner",
  "trackPositionInCluster",
  "secondaryID",
  "secondaryIDMapping",
  "network"
];

export const UPDATE_SAP_MANDATORY_FIELDS = [
  {
    id: DATA_MODEL_TABLE.manufacturer.key,
    label: DATA_MODEL_TABLE.manufacturer.value
  },
  {
    id: DATA_MODEL_TABLE.equipmentModel.key,
    label: DATA_MODEL_TABLE.equipmentModel.value
  },
  {
    id: DATA_MODEL_TABLE.serialNumber.key,
    label: DATA_MODEL_TABLE.serialNumber.value
  },
  {
    id: DATA_MODEL_TABLE.equipmentId.key,
    label: DATA_MODEL_TABLE.equipmentId.value
  },
  {
    id: DATA_MODEL_TABLE.buildingLocation.key,
    label: DATA_MODEL_TABLE.buildingLocation.value
  },
  { id: DATA_MODEL_TABLE.room.key, label: DATA_MODEL_TABLE.room.value },
  {
    id: DATA_MODEL_TABLE.responsiblePerson.key,
    label: DATA_MODEL_TABLE.responsiblePerson.value
  },
  {
    id: DATA_MODEL_TABLE.gxpRelevant.key,
    label: DATA_MODEL_TABLE.gxpRelevant.value
  },
  { id: DATA_MODEL_TABLE.floor.key, label: DATA_MODEL_TABLE.floor.value },
  {
    id: DATA_MODEL_TABLE.configurationBaseline.key,
    label: DATA_MODEL_TABLE.configurationBaseline.value
  }
];

export const IR_MANDATORY_FIELDS = [
  {
    id: DATA_MODEL_TABLE.manufacturer.key,
    label: DATA_MODEL_TABLE.manufacturer.value,
    form: 2
  },
  {
    id: DATA_MODEL_TABLE.equipmentModel.key,
    label: DATA_MODEL_TABLE.equipmentModel.value,
    form: 2
  },
  {
    id: DATA_MODEL_TABLE.equipmentCategory.key,
    label: DATA_MODEL_TABLE.equipmentCategory.value,
    form: 2
  },
  {
    id: DATA_MODEL_TABLE.belongingToGroup.key,
    label: DATA_MODEL_TABLE.belongingToGroup.value,
    form: 3
  },
  {
    id: DATA_MODEL_TABLE.buildingLocation.key,
    label: DATA_MODEL_TABLE.buildingLocation.value,
    form: 3
  },
  {
    id: DATA_MODEL_TABLE.floor.key,
    label: DATA_MODEL_TABLE.floor.value,
    form: 3
  },
  {
    id: DATA_MODEL_TABLE.room.key,
    label: DATA_MODEL_TABLE.room.value,
    form: 3
  },
  {
    id: DATA_MODEL_TABLE.responsiblePerson.key,
    label: DATA_MODEL_TABLE.responsiblePerson.value,
    form: 3
  },
  {
    id: DATA_MODEL_TABLE.qualificationStatus.key,
    label: DATA_MODEL_TABLE.qualificationStatus.value,
    form: 4
  },
  {
    id: DATA_MODEL_TABLE.controlledEquipmentStatus.key,
    label: DATA_MODEL_TABLE.controlledEquipmentStatus.value,
    form: 4
  }
];

export const IR_EDIT_MANDATORY_FIELDS = [
  {
    id: DATA_MODEL_TABLE.manufacturer.key,
    label: DATA_MODEL_TABLE.manufacturer.value,
    form: 1
  },
  {
    id: DATA_MODEL_TABLE.equipmentModel.key,
    label: DATA_MODEL_TABLE.equipmentModel.value,
    form: 1
  },
  {
    id: DATA_MODEL_TABLE.equipmentCategory.key,
    label: DATA_MODEL_TABLE.equipmentCategory.value,
    form: 1
  },
  {
    id: DATA_MODEL_TABLE.belongingToGroup.key,
    label: DATA_MODEL_TABLE.belongingToGroup.value,
    form: 2
  },
  {
    id: DATA_MODEL_TABLE.buildingLocation.key,
    label: DATA_MODEL_TABLE.buildingLocation.value,
    form: 2
  },
  {
    id: DATA_MODEL_TABLE.floor.key,
    label: DATA_MODEL_TABLE.floor.value,
    form: 2
  },
  {
    id: DATA_MODEL_TABLE.room.key,
    label: DATA_MODEL_TABLE.room.value,
    form: 2
  },
  {
    id: DATA_MODEL_TABLE.responsiblePerson.key,
    label: DATA_MODEL_TABLE.responsiblePerson.value,
    form: 2
  },
  {
    id: DATA_MODEL_TABLE.qualificationStatus.key,
    label: DATA_MODEL_TABLE.qualificationStatus.value,
    form: 3
  },
  {
    id: DATA_MODEL_TABLE.controlledEquipmentStatus.key,
    label: DATA_MODEL_TABLE.controlledEquipmentStatus.value,
    form: 3
  }
];
export const listOfFieldsIgnoredInTable = [DATA_MODEL_TABLE?.trackPositionInCluster?.key];

export const defaultFilterObject = () => {
  let obj = {};
  Object.keys(DATA_MODEL_TABLE)?.forEach((dataModel) => {
    if (!listOfFieldsIgnoredInTable.includes(dataModel)) {
      if (dataModel === DATA_MODEL_TABLE?.belongingToGroup?.key) {
        obj["group"] = null;
      }
      if (dataModel === DATA_MODEL_TABLE?.siteName?.key) {
        obj["site"] = null;
      }
      if (dataModel === DATA_MODEL_TABLE?.equipmentCategory?.key) {
        obj["category"] = null;
      }
      obj[dataModel] = null;
    }
  });

  return obj;
};

export const DEFAULT_FILTER = {
  filterName: "",
  filterDefinition: {
    ...defaultFilterObject(),
    dateFrom: null,
    dateTo: null,
    inventoryIds: null
  }
};

export const DEFAULT_COLUMNS = {
  displayName: "",
  displayDefinition: {
    showColumns: [
      {
        key: DATA_MODEL_TABLE.equipmentModel.key,
        val: DATA_MODEL_TABLE.equipmentModel.value,
        order: 1,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.serialNumber.key,
        val: DATA_MODEL_TABLE.serialNumber.value,
        order: 2,
        show: true,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.materialNumber.key,
        val: DATA_MODEL_TABLE.materialNumber.value,
        order: 3,
        show: true,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.siteName.key,
        val: DATA_MODEL_TABLE.siteName.value,
        order: 4,
        show: true,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.manufacturer.key,
        val: DATA_MODEL_TABLE.manufacturer.value,
        order: 5,
        show: true,
        sortStatus: false
      }
    ],
    hideColumns: [
      {
        key: DATA_MODEL_TABLE.instrumentGTIN.key,
        val: "GTIN number",
        order: 6,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.equipmentId.key,
        val: DATA_MODEL_TABLE.equipmentId.value,
        order: 7,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.location.key,
        val: DATA_MODEL_TABLE.location.value,
        order: 8,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.responsiblePerson.key,
        val: DATA_MODEL_TABLE.responsiblePerson.value,
        order: 9,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.isBookable.key,
        val: DATA_MODEL_TABLE.isBookable.value,
        order: 10,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.instrumentRUDI.key,
        val: "RUDI number",
        order: 11,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.equipmentCategory.key,
        val: DATA_MODEL_TABLE.equipmentCategory.value,
        order: 12,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.comment.key,
        val: DATA_MODEL_TABLE.comment.value,
        order: 13,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.belongingToGroup.key,
        val: DATA_MODEL_TABLE.belongingToGroup.value,
        order: 14,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.room.key,
        val: DATA_MODEL_TABLE.room.value,
        order: 15,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.systemOwner.key,
        val: DATA_MODEL_TABLE.systemOwner.value,
        order: 16,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.responsibleProxy.key,
        val: DATA_MODEL_TABLE.responsibleProxy.value,
        order: 17,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.sop.key,
        val: DATA_MODEL_TABLE.sop.value,
        order: 18,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.csv.key,
        val: DATA_MODEL_TABLE.csv.value,
        order: 19,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.electronicRecord.key,
        val: DATA_MODEL_TABLE.electronicRecord.value,
        order: 20,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.electronicSignatures.key,
        val: DATA_MODEL_TABLE.electronicSignatures.value,
        order: 21,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.dateOfNextPeriodicReview.key,
        val: DATA_MODEL_TABLE.dateOfNextPeriodicReview.value,
        order: 22,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.maintenancePlan.key,
        val: DATA_MODEL_TABLE.maintenancePlan.value,
        order: 23,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.gxpRelevant.key,
        val: DATA_MODEL_TABLE.gxpRelevant.value,
        order: 24,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.qualificationStatus.key,
        val: DATA_MODEL_TABLE.qualificationStatus.value,
        order: 25,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.dateOfNextMaintanance.key,
        val: DATA_MODEL_TABLE.dateOfNextMaintanance.value,
        order: 26,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.softwareVersion.key,
        val: "Version",
        order: 27,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.configurationBaseline.key,
        val: DATA_MODEL_TABLE.configurationBaseline.value,
        order: 28,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.installedTests.key,
        val: DATA_MODEL_TABLE.installedTests.value,
        order: 29,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.qualificationDocuments.key,
        val: DATA_MODEL_TABLE.qualificationDocuments.value,
        order: 30,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.isVisualized.key,
        val: DATA_MODEL_TABLE.isVisualized.value,
        order: 31,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.dateOfLastMaintanance.key,
        val: DATA_MODEL_TABLE.dateOfLastMaintanance.value,
        order: 32,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.buildingLocation.key,
        val: DATA_MODEL_TABLE.buildingLocation.value,
        order: 33,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.floor.key,
        val: DATA_MODEL_TABLE.floor.value,
        order: 34,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.cluster.key,
        val: DATA_MODEL_TABLE.cluster.value,
        order: 35,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.equipmentAdministrator.key,
        val: DATA_MODEL_TABLE.equipmentAdministrator.value,
        order: 36,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.equipmentNickName.key,
        val: DATA_MODEL_TABLE.equipmentNickName.value,
        order: 37,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.functionalLocation.key,
        val: DATA_MODEL_TABLE.functionalLocation.value,
        order: 38,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.costCenter.key,
        val: DATA_MODEL_TABLE.costCenter.value,
        order: 39,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.inventoryNumber.key,
        val: DATA_MODEL_TABLE.inventoryNumber.value,
        order: 40,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.variant.key,
        val: DATA_MODEL_TABLE.variant.value,
        order: 41,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.documentedMaintenanceDate.key,
        val: DATA_MODEL_TABLE.documentedMaintenanceDate.value,
        order: 42,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.controlledEquipmentStatus.key,
        val: DATA_MODEL_TABLE.controlledEquipmentStatus.value,
        order: 43,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.maintenanceIntervalInDays.key,
        val: DATA_MODEL_TABLE.maintenanceIntervalInDays.value,
        order: 44,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.weightInkg.key,
        val: DATA_MODEL_TABLE.weightInkg.value,
        order: 45,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.heightInmm.key,
        val: DATA_MODEL_TABLE.heightInmm.value,
        order: 46,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.lengthInmm.key,
        val: DATA_MODEL_TABLE.lengthInmm.value,
        order: 47,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.depthInmm.key,
        val: DATA_MODEL_TABLE.depthInmm.value,
        order: 48,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.clearanceLeftInmm.key,
        val: DATA_MODEL_TABLE.clearanceLeftInmm.value,
        order: 49,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.clearanceRightInmm.key,
        val: DATA_MODEL_TABLE.clearanceRightInmm.value,
        order: 50,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.clearanceHeightInmm.key,
        val: DATA_MODEL_TABLE.clearanceHeightInmm.value,
        order: 51,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.clearanceToFrontInmm.key,
        val: DATA_MODEL_TABLE.clearanceToFrontInmm.value,
        order: 52,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.clearanceBackwardsInmm.key,
        val: DATA_MODEL_TABLE.clearanceBackwardsInmm.value,
        order: 53,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.tags.key,
        val: DATA_MODEL_TABLE.tags.value,
        order: 54,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.linkedInstance.key,
        val: DATA_MODEL_TABLE.linkedInstance.value,
        order: 55,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.systemStatus.key,
        val: DATA_MODEL_TABLE.systemStatus.value,
        order: 56,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.heatOutputInW.key,
        val: DATA_MODEL_TABLE.heatOutputInW.value,
        order: 57,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.pressureEquipment.key,
        val: DATA_MODEL_TABLE.pressureEquipment.value,
        order: 58,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.noiseIndBA.key,
        val: DATA_MODEL_TABLE.noiseIndBA.value,
        order: 59,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.noiseSource.key,
        val: DATA_MODEL_TABLE.noiseSource.value,
        order: 60,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.noiseSourceDetails.key,
        val: DATA_MODEL_TABLE.noiseSourceDetails.value,
        order: 61,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.positioningNote.key,
        val: DATA_MODEL_TABLE.positioningNote.value,
        order: 62,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.positioning.key,
        val: DATA_MODEL_TABLE.positioning.value,
        order: 63,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.vibrationSensitive.key,
        val: DATA_MODEL_TABLE.vibrationSensitive.value,
        order: 64,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.status.key,
        val: DATA_MODEL_TABLE.status.value,
        order: 65,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.createdAt.key,
        val: DATA_MODEL_TABLE.createdAt.value,
        order: 66,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.updatedAt.key,
        val: DATA_MODEL_TABLE.updatedAt.value,
        order: 67,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.network.key,
        val: DATA_MODEL_TABLE.network.value,
        order: 69,
        show: false,
        sortStatus: false
      },
      {
        label: DATA_MODEL_TABLE.checkInRequired.value,
        field: DATA_MODEL_TABLE.checkInRequired.key,
        order: 70,
        show: false,
        sortStatus: true
      },
      {
        label: DATA_MODEL_TABLE.checkInStartPeriod.value,
        field: DATA_MODEL_TABLE.checkInStartPeriod.key,
        order: 71,
        show: false,
        sortStatus: false
      },
      {
        label: DATA_MODEL_TABLE.checkInGracePeriod.value,
        field: DATA_MODEL_TABLE.checkInGracePeriod.key,
        order: 72,
        show: false,
        sortStatus: false
      },
      {
        label: DATA_MODEL_TABLE.configurationDetails.value,
        field: DATA_MODEL_TABLE.configurationDetails.key,
        order: 73,
        show: false,
        sortStatus: false
      }
    ]
  }
};
export const MY_LAB = "My lab";
export const SOP_RT = "SOP RT0020";
export const YES = "Yes";
export const DEFAULT_MYLAB_FILTER = {
  filterName: MY_LAB,
  filterDefinition: {
    ...defaultFilterObject(),
    dateFrom: null,
    dateTo: null,
    inventoryIds: null
  }
};

export const filterKeyMapping = {
  serialNumber: DATA_MODEL_TABLE?.serialNumber?.key,
  materialNumber: DATA_MODEL_TABLE?.materialNumber?.key,
  equipmentId: DATA_MODEL_TABLE?.equipmentId?.key,
  tags: DATA_MODEL_TABLE?.tags?.key,
  linkedInstance: DATA_MODEL_TABLE?.linkedInstance?.key,
  site: DATA_MODEL_TABLE?.siteName?.key,
  group: DATA_MODEL_TABLE?.belongingToGroup?.key,
  manufacturer: DATA_MODEL_TABLE?.manufacturer?.key,
  category: DATA_MODEL_TABLE?.equipmentCategory?.key,
  responsiblePerson: DATA_MODEL_TABLE?.responsiblePerson?.key,
  equipmentAdministrator: DATA_MODEL_TABLE?.equipmentAdministrator?.key,
  dateOfNextMaintanance: DATA_MODEL_TABLE?.dateOfNextMaintanance?.key,
  dateFrom: "dateFrom",
  dateTo: "dateTo",
  inventoryIds: "_id"
};

export const primarySources = [
  {
    key: "MANUAL",
    value: "MANUAL"
  },
  { key: "RAM", value: "RAM" },
  { key: "SAP", value: "SAP" }
];

export const UPLOAD_FILE_PROPERTIES = {
  allowedExtensions: /(\.csv)$/i,
  allowedSize: 5,
  allowedFileType: ".csv",
  allowedContentType: "text/csv"
};

export const MASTER_DATA_LIST = [
  {
    listName: "Clusters: Category - Model - Variant",
    listKey: "Clusters: Category - Model - Variant",
    targetModule: "CLUSTER",
    isDisabled: false
  },

  {
    listName: "Equipment: Manufacturer - Category - Model - Variant",
    listKey: "Equipment: Manufacturer - Category - Model - Variant",
    targetModule: "CATEGORYMAPPING",
    isDisabled: false
  },
  {
    listName: DATA_MODEL_TABLE.sop.value,
    listKey: DATA_MODEL_TABLE.sop.value,
    targetModule: DATA_MODEL_TABLE.sop.value,
    isDisabled: false
  },
  {
    listName: "Equipment: Manufacturer - Category - Model",
    listKey: "Equipment: Manufacturer - Category - Model",
    targetModule: "Category",
    isDisabled: true
  },
  {
    listName: "Equipment: Model - Variant",
    listKey: "Equipment: Model - Variant",
    targetModule: "Variant",
    isDisabled: true
  }
];

export const IR_FILE_UPLOAD_STATUS = [
  {
    statusKey: "SUBMITTED",
    statusText: "Submitted",
    statusClass: "submittedStatus"
  },
  {
    statusKey: "INPROGRESS",
    statusText: "In progress",
    statusClass: "inprogressStatus"
  },
  {
    statusKey: "SUCCESS",
    statusText: "Completed",
    statusClass: "completedStatus"
  },
  {
    statusKey: "FAILED",
    statusText: "Failed",
    statusClass: "failedStatus"
  }
];

export const IR_UPLOAD_STATUS_MODEL_NAME = "IRUPLOADSTATUS";

export const IR_EQUIPMENT_UPLOAD_TARGET_MODULE = "EQUIPMENT";
export const EQUIPMENT_ERROR_TARGET_MODULE = "EQUIPMENTERRORS";
export const MASTER_DATA_ERROR_TARGET_MODULE = "MASTERDATAERRORS";

export const DEFAULT_IR_PAGE_SIZE = {
  ir_grid: 10,
  ir_group: 5,
  ir_reason: 5,
  ir_status_log: 10,
  ir_master_page: 5
};

export const DEBOUNCE_DELAY = 1000;

export const DEFAULT_SEARCH_FILTER = DATA_MODEL_TABLE.serialNumber.key;
export const SEARCH_PREFERENCE_LIST = [
  {
    title: DATA_MODEL_TABLE.cluster.value,
    coloumn: DATA_MODEL_TABLE.cluster.key,
    checked: true
  },
  {
    title: DATA_MODEL_TABLE.equipmentId.value,
    coloumn: DATA_MODEL_TABLE.equipmentId.key,
    checked: true
  },
  {
    title: DATA_MODEL_TABLE.equipmentModel.value,
    coloumn: DATA_MODEL_TABLE.equipmentModel.key,
    checked: true
  },
  {
    title: DATA_MODEL_TABLE.equipmentNickName.value,
    coloumn: DATA_MODEL_TABLE.equipmentNickName.key,
    checked: true
  },
  {
    title: DATA_MODEL_TABLE.serialNumber.value,
    coloumn: DATA_MODEL_TABLE.serialNumber.key,
    checked: true
  }
];

export const SORTING_COLOUMN_ARRAY = [DATA_MODEL_TABLE.serialNumber.key, DATA_MODEL_TABLE.equipmentModel.key];
export const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const BULK_EDIT_LABELS = {
  DATE_OF_EXECUTED_MAINTENANCE: "Date of executed maintenance",
  NEXT_MAINTENANCE_OR_CALIBRATION: "Next maintenance or calibration",
  MAINTENANCE_DATES_DOCUMENTED: "Maintenance dates documented elsewhere?",
  INVALID_DATE_FORMAT_ERROR_MESSAGE: "Invalid date format"
};

export const DEFAULT_RECORDS_FETCHED = 900;
export const DEFAULT_DESCRIPTION_CONSTANT = {
  moduleVersion: "MODULE VERSION",
  moduleName: "APP NAME",
  ciJobId: "CI ID JOB",
  commitHash: "COMMIT HASH",
  updatedAt: "UPDATED AT"
};
export const ALL_EQUIPMENT_KEY = [
  {
    key: DATA_MODEL_TABLE.equipmentCategory.key,
    label: DATA_MODEL_TABLE.equipmentCategory.value
  },
  {
    key: DATA_MODEL_TABLE.manufacturer.key,
    label: DATA_MODEL_TABLE.manufacturer.value
  },
  {
    key: DATA_MODEL_TABLE.equipmentModel.key,
    label: DATA_MODEL_TABLE.equipmentModel.value
  },
  { key: DATA_MODEL_TABLE.variant.key, label: DATA_MODEL_TABLE.variant.value },
  {
    key: DATA_MODEL_TABLE.equipmentNickName.key,
    label: DATA_MODEL_TABLE.equipmentNickName.value
  },
  {
    key: DATA_MODEL_TABLE.location.key,
    label: DATA_MODEL_TABLE.location.value
  },
  {
    key: DATA_MODEL_TABLE.serialNumber.key,
    label: DATA_MODEL_TABLE.serialNumber.value
  },
  {
    key: DATA_MODEL_TABLE.materialNumber.key,
    label: DATA_MODEL_TABLE.materialNumber.value
  },
  {
    key: DATA_MODEL_TABLE.equipmentId.key,
    label: DATA_MODEL_TABLE.equipmentId.value
  }
];

export const SHOW_COMMON_KEY = [
  {
    key: DATA_MODEL_TABLE.equipmentCategory.key,
    label: DATA_MODEL_TABLE.equipmentCategory.value
  },
  {
    key: DATA_MODEL_TABLE.manufacturer.key,
    label: DATA_MODEL_TABLE.manufacturer.value
  },
  {
    key: DATA_MODEL_TABLE.equipmentModel.key,
    label: DATA_MODEL_TABLE.equipmentModel.value
  },
  { key: DATA_MODEL_TABLE.variant.key, label: DATA_MODEL_TABLE.variant.value },
  {
    key: DATA_MODEL_TABLE.equipmentNickName.key,
    label: DATA_MODEL_TABLE.equipmentNickName.value
  },
  {
    key: DATA_MODEL_TABLE.location.key,
    label: DATA_MODEL_TABLE.location.value
  },
  {
    key: DATA_MODEL_TABLE.serialNumber.key,
    label: DATA_MODEL_TABLE.serialNumber.value
  }
];

export const SHOW_COMMON_CLUSTER_DELETE_KEY = [
  {
    key: DATA_MODEL_TABLE.equipmentCategory.key,
    label: DATA_MODEL_TABLE.equipmentCategory.value
  },
  {
    key: DATA_MODEL_TABLE.equipmentModel.key,
    label: DATA_MODEL_TABLE.equipmentModel.value
  },
  { key: DATA_MODEL_TABLE.variant.key, label: DATA_MODEL_TABLE.variant.value },
  {
    key: DATA_MODEL_TABLE.equipmentNickName.key,
    label: DATA_MODEL_TABLE.equipmentNickName.value
  },
  {
    key: DATA_MODEL_TABLE.siteName.key,
    label: DATA_MODEL_TABLE.siteName.value
  },
  { key: DATA_MODEL_TABLE.location.key, label: DATA_MODEL_TABLE.location.value }
];

export const SHOW_COMMON_CLUSTER_AUDIT_TRAIL_KEY = [
  {
    key: DATA_MODEL_TABLE.equipmentCategory.key,
    label: DATA_MODEL_TABLE.equipmentCategory.value
  },
  {
    key: DATA_MODEL_TABLE.belongingToGroup.key,
    label: DATA_MODEL_TABLE.belongingToGroup.value
  },
  {
    key: DATA_MODEL_TABLE.equipmentNickName.key,
    label: DATA_MODEL_TABLE.equipmentNickName.value
  },
  {
    key: DATA_MODEL_TABLE.equipmentModel.key,
    label: DATA_MODEL_TABLE.equipmentModel.value
  },
  { key: DATA_MODEL_TABLE.variant.key, label: DATA_MODEL_TABLE.variant.value },
  {
    key: DATA_MODEL_TABLE.siteName.key,
    label: DATA_MODEL_TABLE.siteName.value
  },
  {
    key: DATA_MODEL_TABLE.location.key,
    label: DATA_MODEL_TABLE.location.value
  },
  {
    key: DATA_MODEL_TABLE.responsiblePerson.key,
    label: DATA_MODEL_TABLE.responsiblePerson.value
  },
  {
    key: DATA_MODEL_TABLE.responsibleProxy.key,
    label: DATA_MODEL_TABLE.responsibleProxy.value
  }
];
export const RMD_SAP_INSTANCE = "RMD SAP";

export const AUDIT_ACTIONS = {
  CREATE: "CREATE",
  MODIFY: "MODIFY",
  DELETE: "DELETE",
  REACTIVATE: "REACTIVATE",
  CLUSTER_CREATE: "CLUSTER_CREATE",
  CLUSTER_DELETE: "CLUSTER_DELETE",
  ADD_TO_CLUSTER: "ADD_TO_CLUSTER",
  REMOVE_FROM_CLUSTER: "REMOVE_FROM_CLUSTER",
  CLUSTER_UPDATE: "CLUSTER_UPDATE",
  PENDING: "PENDING",
  APPROVE: "APPROVE",
  REJECT: "REJECT",
  REVERT: "REVERT",
  SIGNED_APPROVE: "SIGNED_APPROVE"
};

export const AUDIT_ACTION_BY = {
  CREATE: "Created by",
  CREATE_4EP: "Created (Pending) by",
  MODIFY: "Edited by",
  DELETE: "Deleted by",
  DELETE_4EP: "Deleted (Pending) by",
  REACTIVATE: "Restored by",
  REACTIVATE_4EP: "Restored (Pending) by",
  CLUSTER_CREATE: "Commissioned by",
  CLUSTER_DELETE: "Decommissioned by",
  ADD_TO_CLUSTER: "Edited by",
  REMOVE_FROM_CLUSTER: "Edited by",
  CLUSTER_UPDATE: "Edited by",
  PENDING: "Edited by",
  APPROVE: "Approved by",
  REJECT: "Rejected by",
  REVERT: "Reverted by",
  SIGNED_APPROVE: "Approved by"
};
export const GXP_DROP_CHECK = [
  DATA_MODEL_TABLE.csv.key,
  DATA_MODEL_TABLE.electronicSignatures.key,
  DATA_MODEL_TABLE.electronicRecord.key,
  DATA_MODEL_TABLE.gxpRelevant.key,
  DATA_MODEL_TABLE.qualificationStatus.key,
  DATA_MODEL_TABLE.controlledEquipmentStatus.key
];

export const SHOW_INSTRUMENT = "SHOW_INSTRUMENT";
export const MODIFY_INSTRUMENT = "MODIFY_INSTRUMENT";
export const SHOW_CLUSTER = "SHOW_CLUSTER";
export const MODIFY_CLUSTER = "MODIFY_CLUSTER";
export const BULK_EDIT_ACTIVE = "BULK-EDIT-ACTIVE";
export const NORMAL_BULK_EDIT = "NORMAL_BULK_EDIT";
export const SHOW_REVIEW_MODAL = "SHOW_REVIEW_MODAL";
export const SEARCH_FILTER_QUERY = "SEARCH_FILTER_QUERY";
export const LIMIT_EDIT_FILTER = 10000;

export const GROUP_LITERAL = "belonging_to_group_literal";

export const literalFields = [
  {
    key: DATA_MODEL_TABLE?.belongingToGroup?.key,
    value: GROUP_LITERAL
  }
];

export const CLUSTER_STEPS = [
  {
    id: 0,
    description: "Cluster information",
    error: false,
    valid: false,
    completed: false,
    active: true,
    cursor: "default",
    tooltip: "Step 1: Definition of basic cluster information"
  },
  {
    id: 1,
    description: "Add components",
    error: false,
    valid: false,
    completed: false,
    active: false,
    cursor: "default",
    tooltip: "Step 2: Definition of included components for the cluster"
  },
  {
    id: 2,
    description: "Cluster components",
    error: false,
    valid: false,
    completed: false,
    active: false,
    cursor: "pointer",
    tooltip: "Step 3: Definition of position relevance and sorting behavior for the cluster"
  }
];
export const subEquipment = "SUBEQUIPMENT";
export const entryType = {
  cluster: "CLUSTER",
  standaloneEquipment: "STANDALONE-EQUIPMENT",
  clusterSubequipment: "CLUSTER-SUBEQUIPMENT"
};

export const emptyClusterInformation = {
  siteName: null,
  siteTimezone: null,
  siteId: null,
  buildingLocation: null,
  location: "",
  floor: null,
  room: null,
  equipmentAdministrator: null,
  responsiblePerson: null,
  responsibleProxy: null,
  belongingToGroup: null,
  equipmentCategory: null,
  equipmentModel: null,
  manufacturer: null,
  variant: null,
  equipmentNickName: null,
  cluster: null,
  isBookable: false,
  isVisualized: false,
  qualificationDocuments: {
    isSynchronized: false,
    value: []
  },
  qualificationStatus: null,
  gxpRelevant: null,
  dateOfNextMaintanance: null,
  secondaryID: entryType.cluster,
  secondaryIDMapping: entryType.cluster,
  linkedInstance: entryType.cluster,
  entryType: entryType.cluster,
  action: "CREATE",
  subEquipments: [],
  trackPositionInCluster: false,
  softwareVersion: null,
  leadingSoftwareVersionId: null,
  tags: [],
  systemStatus: null
};
export const SHOW_CLUSTER_KEY = [
  {
    key: DATA_MODEL_TABLE.equipmentModel.key,
    label: DATA_MODEL_TABLE.equipmentModel.value
  },
  {
    key: DATA_MODEL_TABLE.equipmentNickName.key,
    label: DATA_MODEL_TABLE.equipmentNickName.value
  },
  { key: DATA_MODEL_TABLE.location.key, label: DATA_MODEL_TABLE.location.value }
];
export const SHOW_SUB_CLUSTER_KEY = [
  {
    key: DATA_MODEL_TABLE.manufacturer.key,
    label: DATA_MODEL_TABLE.manufacturer.value
  },
  {
    key: DATA_MODEL_TABLE.equipmentModel.key,
    label: DATA_MODEL_TABLE.equipmentModel.value
  },
  {
    key: DATA_MODEL_TABLE.equipmentNickName.key,
    label: DATA_MODEL_TABLE.equipmentNickName.value
  },
  {
    key: DATA_MODEL_TABLE.serialNumber.key,
    label: DATA_MODEL_TABLE.serialNumber.value
  },
  {
    key: DATA_MODEL_TABLE.equipmentId.key,
    label: DATA_MODEL_TABLE.equipmentId.value
  },
  { key: DATA_MODEL_TABLE.location.key, label: DATA_MODEL_TABLE.location.value }
];

export const SHOW_SUBEQUIPMENT_KEY = [
  {
    key: DATA_MODEL_TABLE.equipmentModel.key,
    label: DATA_MODEL_TABLE.equipmentModel.value
  },
  { key: DATA_MODEL_TABLE.variant.key, label: DATA_MODEL_TABLE.variant.value },
  {
    key: DATA_MODEL_TABLE.equipmentNickName.key,
    label: DATA_MODEL_TABLE.equipmentNickName.value
  },
  { key: DATA_MODEL_TABLE.location.key, label: DATA_MODEL_TABLE.location.value }
];
export const defaultinstrumentTableValues = {
  fetching: false,
  prevTokens: [],
  nextToken: null,
  nextData: 0,
  limit: DEFAULT_IR_PAGE_SIZE.ir_grid,
  data: [],
  sortDirection: "ASC",
  orderBy: "",
  refreshData: 0
};

export const defaultTableOptions = {
  showTitleNav: true,
  showTopBar: true,
  showAddClusterEquipBtn: false,
  showPreDefinedFilter: true,
  showPreDefinedColumn: true,
  showFilterSettings: true,
  showTopBarActions: true,
  settings: {
    showSelectColumn: true,
    showFilter: true,
    showGroup: true,
    showReason: true,
    showMasterData: true
  },
  actions: {
    showDetailedView: true,
    showEdit: true,
    showAudit: true,
    showDelete: true
  }
};

export const CLUSTER_ADD_LIMIT = 50;

export const AUDIT_CLUSTER_ACTION = {
  cluster: "cluster",
  subEquipmentRemove: "subEquipmentRemove",
  subEquipmentAdd: "subEquipmentAdd"
};
export const AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE = {
  Created: "Created",
  Updated: "Updated",
  Deleted: "Deleted"
};
export const SOP_MANDATORY_CLP = "SOP RT0020 mandatory (CLP only)";
export const CORELAB = "Corelab SAP";
export const RMDSAP = "RMD SAP";
export const CLUSTER_EQUIPMENT_POPOVER_DETAILS = {
  siteName: {
    key: "Site"
  },
  location: {
    key: "Location"
  },
  equipmentCategory: {
    key: "Category"
  },
  equipmentModel: {
    key: "Model"
  },
  variant: {
    key: "Variant"
  },
  equipmentNickName: {
    key: "Nickname"
  },
  responsiblePerson: {
    key: "Responsible person"
  },
  qualificationStatus: {
    key: "Qualification status"
  }
};
export const CLUSTER_COMPONENT_DATA_MODEL = {
  softwareVersion: {
    key: "softwareVersion",
    value: "Leading cluster software"
  }
};
export const CLUSTER_SUB_EQUIPMENT_OBJ = {
  index: "",
  equipmentModel: "Model",
  variant: "Variant",
  equipmentNickName: "Nickname",
  qualificationStatus: "Qualification status",
  softwareVersion: "Software version"
};
export const ADMIN_ROLE = "Admin";

export const equipmentImportStepContent = [
  {
    stepHeader: `Select type and download template`,
    stepBodyNewLine: `This step is obsolete if you have already downloaded a CSV for bulk
    changes on equipment.`,
    stepBody: `In this step you can download a template in CSV format filled with
              sample/dummy data.`,
    stepFooter: `You can not only add new
              equipment but update existing equipment using the identifier.`
  },
  {
    stepHeader: `Prepare and import new CSV`,
    stepBody: `After editing entries, either from gSheet or Excel, save again as
              CSV and import the file.`,
    stepFooter: `To see errors, download the error file from
              history below.`
  }
];

export const masterDataImportStepContent = [
  {
    stepHeader: `Select type and download data`,
    stepBody: `In this step you can download existing data in CSV format for the selected type and add or edit entries.`,
    stepFooter: `You can remove entries that you are not going to modify.`
  },
  {
    stepHeader: `Edit and import new CSV`,
    stepBody: `After editing entries, either in gSheet or Excel, save again as CSV and import the modified file.`,
    stepFooter: `To disable an entry set Active status to False, this entry will no longer be visible to users.`
  }
];

export const listOfFieldsNoFilter = [];

export const listOfCheckBoxFilters = [
  DATA_MODEL_TABLE?.siteName?.key,
  DATA_MODEL_TABLE?.belongingToGroup?.key,
  DATA_MODEL_TABLE?.manufacturer?.key,
  DATA_MODEL_TABLE?.equipmentCategory?.key,
  DATA_MODEL_TABLE?.responsiblePerson?.key,
  DATA_MODEL_TABLE?.equipmentAdministrator?.key,
  DATA_MODEL_TABLE?.tags?.key,
  DATA_MODEL_TABLE?.linkedInstance?.key,
  DATA_MODEL_TABLE?.equipmentModel?.key,
  DATA_MODEL_TABLE?.variant?.key,
  DATA_MODEL_TABLE?.csv?.key,
  DATA_MODEL_TABLE?.electronicRecord?.key,
  DATA_MODEL_TABLE?.electronicSignatures?.key,
  DATA_MODEL_TABLE?.qualificationStatus?.key,
  DATA_MODEL_TABLE?.controlledEquipmentStatus?.key,
  DATA_MODEL_TABLE?.sop?.key,
  DATA_MODEL_TABLE?.gxpRelevant?.key,
  DATA_MODEL_TABLE?.systemStatus?.key,
  DATA_MODEL_TABLE?.status?.key
];

export const listOfBooleanRequired = [
  DATA_MODEL_TABLE?.isBookable?.key,
  DATA_MODEL_TABLE?.isVisualized?.key,
  DATA_MODEL_TABLE?.isActive?.key,
  DATA_MODEL_TABLE?.checkInRequired?.key
];

export const listOfDateRequired = [
  DATA_MODEL_TABLE?.dateOfLastMaintanance?.key,
  DATA_MODEL_TABLE?.dateOfNextMaintanance?.key,
  DATA_MODEL_TABLE?.dateOfNextPeriodicReview?.key,
  DATA_MODEL_TABLE?.createdAt?.key,
  DATA_MODEL_TABLE?.updatedAt?.key
];

export const listOfNumberFilter = [
  DATA_MODEL_TABLE?.inventoryNumber?.key,
  DATA_MODEL_TABLE?.installedTests?.key,
  DATA_MODEL_TABLE?.qualificationDocuments?.key,
  DATA_MODEL_TABLE?.maintenanceIntervalInDays?.key,
  DATA_MODEL_TABLE?.heatOutputInW?.key,
  DATA_MODEL_TABLE?.noiseIndBA?.key,
  DATA_MODEL_TABLE?.heightInmm?.key,
  DATA_MODEL_TABLE?.lengthInmm?.key,
  DATA_MODEL_TABLE?.depthInmm?.key,
  DATA_MODEL_TABLE?.clearanceBackwardsInmm?.key,
  DATA_MODEL_TABLE?.clearanceHeightInmm?.key,
  DATA_MODEL_TABLE?.clearanceLeftInmm?.key,
  DATA_MODEL_TABLE?.clearanceRightInmm?.key,
  DATA_MODEL_TABLE?.clearanceToFrontInmm?.key,
  DATA_MODEL_TABLE?.weightInkg?.key, // this is double
  DATA_MODEL_TABLE?.checkInStartPeriod?.key,
  DATA_MODEL_TABLE?.checkInGracePeriod?.key
];

export const listOfFieldAcceptOnlyInteger = [
  DATA_MODEL_TABLE?.inventoryNumber?.key,
  DATA_MODEL_TABLE?.installedTests?.key,
  DATA_MODEL_TABLE?.qualificationDocuments?.key,
  DATA_MODEL_TABLE?.maintenanceIntervalInDays?.key,
  DATA_MODEL_TABLE?.heatOutputInW?.key,
  DATA_MODEL_TABLE?.noiseIndBA?.key,
  DATA_MODEL_TABLE?.heightInmm?.key,
  DATA_MODEL_TABLE?.lengthInmm?.key,
  DATA_MODEL_TABLE?.depthInmm?.key,
  DATA_MODEL_TABLE?.clearanceBackwardsInmm?.key,
  DATA_MODEL_TABLE?.clearanceHeightInmm?.key,
  DATA_MODEL_TABLE?.clearanceLeftInmm?.key,
  DATA_MODEL_TABLE?.clearanceRightInmm?.key,
  DATA_MODEL_TABLE?.clearanceToFrontInmm?.key,
  DATA_MODEL_TABLE?.checkInStartPeriod?.key,
  DATA_MODEL_TABLE?.checkInGracePeriod?.key
];

export const listOfFilterBlankRequired = [
  DATA_MODEL_TABLE?.serialNumber?.key,
  DATA_MODEL_TABLE?.materialNumber?.key,
  DATA_MODEL_TABLE?.equipmentId?.key
];
export const listOfFieldsNotSortable = [DATA_MODEL_TABLE?.tags?.key, DATA_MODEL_TABLE?.sop?.key];

export const listOfFieldsSortable = [
  DATA_MODEL_TABLE?.responsiblePerson?.key,
  DATA_MODEL_TABLE?.location?.key,
  DATA_MODEL_TABLE?.belongingToGroup?.key,
  DATA_MODEL_TABLE?.serialNumber?.key,
  DATA_MODEL_TABLE?.equipmentModel?.key
];

export const listOfFieldsIgnoredForSearch = [
  DATA_MODEL_TABLE?.dateOfLastMaintanance?.key,
  DATA_MODEL_TABLE?.dateOfNextMaintanance?.key,
  DATA_MODEL_TABLE?.dateOfNextPeriodicReview?.key,
  DATA_MODEL_TABLE?.createdAt?.key,
  DATA_MODEL_TABLE?.updatedAt?.key,
  DATA_MODEL_TABLE?.configurationDetails?.key
];

export const TOOL_PANELS = {
  columns: "columns",
  filters: "filters",
  saveConfiguration: "save_configuration"
};

export const linkedInstanceExtraFilterFields = [
  DATA_MODEL_TABLE?.manufacturer?.key,
  DATA_MODEL_TABLE?.siteName?.key,
  DATA_MODEL_TABLE?.responsiblePerson?.key,
  DATA_MODEL_TABLE?.equipmentCategory?.key,
  DATA_MODEL_TABLE?.equipmentAdministrator?.key,
  DATA_MODEL_TABLE?.dateOfNextMaintanance?.key
];

export const conversionFields = [
  DATA_MODEL_TABLE?.weightInkg?.key,
  DATA_MODEL_TABLE?.heightInmm?.key,
  DATA_MODEL_TABLE?.lengthInmm?.key,
  DATA_MODEL_TABLE?.depthInmm?.key,
  DATA_MODEL_TABLE?.clearanceBackwardsInmm?.key,
  DATA_MODEL_TABLE?.clearanceHeightInmm?.key,
  DATA_MODEL_TABLE?.clearanceLeftInmm?.key,
  DATA_MODEL_TABLE?.clearanceRightInmm?.key,
  DATA_MODEL_TABLE?.clearanceToFrontInmm?.key
];

const EQUIP_DATA_MODEL_TABLE = {
  [DATA_MODEL_TABLE?.belongingToGroup?.key]: {
    key: DATA_MODEL_TABLE?.belongingToGroup?.key,
    value: DATA_MODEL_TABLE?.belongingToGroup?.value
  },
  [DATA_MODEL_TABLE?.equipmentId?.key]: {
    key: DATA_MODEL_TABLE?.equipmentId?.key,
    value: DATA_MODEL_TABLE?.equipmentId?.value
  },
  [DATA_MODEL_TABLE?.materialNumber?.key]: {
    key: DATA_MODEL_TABLE?.materialNumber?.key,
    value: DATA_MODEL_TABLE?.materialNumber?.value
  },
  [DATA_MODEL_TABLE?.serialNumber?.key]: {
    key: DATA_MODEL_TABLE?.serialNumber?.key,
    value: DATA_MODEL_TABLE?.serialNumber?.value
  },
  [DATA_MODEL_TABLE?.linkedInstance?.key]: {
    key: DATA_MODEL_TABLE?.linkedInstance?.key,
    value: DATA_MODEL_TABLE?.linkedInstance?.value
  }
};

export default EQUIP_DATA_MODEL_TABLE;

export const changeLinkedInstanceStatusEnum = {
  SUBMITTED: "SUBMITTED",
  INPROGRESS: "INPROGRESS",
  COMPLETED: "COMPLETED"
};

export const DELETE_REASON_DIALOG_MSG = "Do you want to delete the reason?";
export const ADD_REASON = "Add reason";
export const OLD_SCHEMA_FIELDS = {
  instrumentName: { key: "instrumentName", value: "Instrument name" },
  instrumentType: { key: "instrumentType", value: "Type" },
  secondResponsiblePerson: {
    key: "secondResponsiblePerson",
    value: "Second responsible person"
  },
  instrumentGxPStatus: { key: "instrumentGxPStatus", value: "GxP status" },
  remark: { key: "remark", value: "Remark" }
};

export const dropDownSelectionSource = {
  agGridFilter: "AG_GRID_FILTER",
  agGridColumn: "AG_GRID_COLUMN",
  nonAgGridFilter: "NON_AG_GRID_FILTER",
  nonAgGridColumn: "NON_AG_GRID_COLUMN"
};

export const dimentionUnits = {
  imperialUnit: "imperial",
  metricUnit: "metric"
};
export const systemStatusOptions = ["In service", "Out of service"];
export const equipmentStatuses = [
  equipmentStatus?.active?.value,
  equipmentStatus?.deleted?.value,
  equipmentStatus?.pending?.value
];

export const masterDataComplexAttributePressureEquipment = ["Yes", "No"];
export const masterDataComplexAttributeNoiseIndBA = ["Onsite measurement", "Value taken from vendor", "Other"];
export const masterDataComplexAttributePositioning = ["Tabletop", "On the ground", "Stack"];
export const masterDataComplexAttributeVibrationSensitive = ["Yes", "No"];

export const MASTER_DATA_MODEL_TABLE = {
  [DATA_MODEL_TABLE?.equipmentCategory?.key]: {
    key: DATA_MODEL_TABLE?.equipmentCategory?.key,
    value: DATA_MODEL_TABLE?.equipmentCategory?.value
  },
  [DATA_MODEL_TABLE?.manufacturer?.key]: {
    key: DATA_MODEL_TABLE?.manufacturer?.key,
    value: DATA_MODEL_TABLE?.manufacturer?.value
  },
  [DATA_MODEL_TABLE?.equipmentModel?.key]: {
    key: DATA_MODEL_TABLE?.equipmentModel?.key,
    value: DATA_MODEL_TABLE?.equipmentModel?.value
  },
  [DATA_MODEL_TABLE?.variant?.key]: {
    key: DATA_MODEL_TABLE?.variant?.key,
    value: DATA_MODEL_TABLE?.variant?.value
  },
  [DATA_MODEL_TABLE?.displayImage?.key]: {
    key: DATA_MODEL_TABLE?.displayImage?.key,
    value: DATA_MODEL_TABLE?.displayImage?.value
  },
  [DATA_MODEL_TABLE?.isActive?.key]: {
    key: DATA_MODEL_TABLE?.isActive?.key,
    value: DATA_MODEL_TABLE?.isActive?.value
  },
  [DATA_MODEL_TABLE?.weightInkg?.key]: {
    key: DATA_MODEL_TABLE?.weightInkg?.key,
    value: DATA_MODEL_TABLE?.weightInkg?.value
  },
  [DATA_MODEL_TABLE?.heightInmm?.key]: {
    key: DATA_MODEL_TABLE?.heightInmm?.key,
    value: DATA_MODEL_TABLE?.heightInmm?.value
  },
  [DATA_MODEL_TABLE?.lengthInmm?.key]: {
    key: DATA_MODEL_TABLE?.lengthInmm?.key,
    value: DATA_MODEL_TABLE?.lengthInmm?.value
  },
  [DATA_MODEL_TABLE?.depthInmm?.key]: {
    key: DATA_MODEL_TABLE?.depthInmm?.key,
    value: DATA_MODEL_TABLE?.depthInmm?.value
  },
  [DATA_MODEL_TABLE?.clearanceLeftInmm?.key]: {
    key: DATA_MODEL_TABLE?.clearanceLeftInmm?.key,
    value: DATA_MODEL_TABLE?.clearanceLeftInmm?.value
  },
  [DATA_MODEL_TABLE?.clearanceRightInmm?.key]: {
    key: DATA_MODEL_TABLE?.clearanceRightInmm?.key,
    value: DATA_MODEL_TABLE?.clearanceRightInmm?.value
  },
  [DATA_MODEL_TABLE?.clearanceHeightInmm?.key]: {
    key: DATA_MODEL_TABLE?.clearanceHeightInmm?.key,
    value: DATA_MODEL_TABLE?.clearanceHeightInmm?.value
  },
  [DATA_MODEL_TABLE?.clearanceToFrontInmm?.key]: {
    key: DATA_MODEL_TABLE?.clearanceToFrontInmm?.key,
    value: DATA_MODEL_TABLE?.clearanceToFrontInmm?.value
  },
  [DATA_MODEL_TABLE?.clearanceBackwardsInmm?.key]: {
    key: DATA_MODEL_TABLE?.clearanceBackwardsInmm?.key,
    value: DATA_MODEL_TABLE?.clearanceBackwardsInmm?.value
  },
  [DATA_MODEL_TABLE.heatOutputInW.key]: {
    key: DATA_MODEL_TABLE?.heatOutputInW?.key,
    value: DATA_MODEL_TABLE?.heatOutputInW?.value
  },
  [DATA_MODEL_TABLE.pressureEquipment.key]: {
    key: DATA_MODEL_TABLE?.pressureEquipment?.key,
    value: DATA_MODEL_TABLE?.pressureEquipment?.value
  },
  [DATA_MODEL_TABLE.noiseIndBA.key]: {
    key: DATA_MODEL_TABLE?.noiseIndBA?.key,
    value: DATA_MODEL_TABLE?.noiseIndBA?.value
  },
  [DATA_MODEL_TABLE.noiseSource.key]: {
    key: DATA_MODEL_TABLE?.noiseSource?.key,
    value: DATA_MODEL_TABLE?.noiseSource?.value
  },
  [DATA_MODEL_TABLE.noiseSourceDetails.key]: {
    key: DATA_MODEL_TABLE?.noiseSourceDetails?.key,
    value: DATA_MODEL_TABLE?.noiseSourceDetails?.value
  },
  [DATA_MODEL_TABLE.positioningNote.key]: {
    key: DATA_MODEL_TABLE?.positioningNote?.key,
    value: DATA_MODEL_TABLE?.positioningNote?.value
  },
  [DATA_MODEL_TABLE.positioning.key]: {
    key: DATA_MODEL_TABLE?.positioning?.key,
    value: DATA_MODEL_TABLE?.positioning?.value
  },
  [DATA_MODEL_TABLE.vibrationSensitive.key]: {
    key: DATA_MODEL_TABLE?.vibrationSensitive?.key,
    value: DATA_MODEL_TABLE?.vibrationSensitive?.value
  }
};

export const MASTER_DATA_STEPS = [
  {
    id: 0,
    description: "Basic attributes",
    error: false,
    valid: false,
    completed: false,
    active: true,
    cursor: "default",
    tooltip: "Add equipment master data basic attributes"
  },
  {
    id: 1,
    description: "Physical dimensions & clearance",
    error: false,
    valid: false,
    completed: false,
    active: false,
    cursor: "default",
    tooltip: "Add equipment master data physical dimensions & clearance"
  },
  {
    id: 2,
    description: "Complex attributes",
    error: false,
    valid: false,
    completed: false,
    active: false,
    cursor: "default",
    tooltip: "Add equipment master data complex attribute"
  }
];

export const emptyAttributes = {
  equipmentCategory: "",
  manufacturer: "",
  equipmentModel: "",
  variant: "",
  displayImage: "",
  isActive: true
};

export const emptyDimensions = {
  weightInkg: null,
  heightInmm: null,
  lengthInmm: null,
  depthInmm: null,
  clearanceLeftInmm: null,
  clearanceRightInmm: null,
  clearanceHeightInmm: null,
  clearanceToFrontInmm: null,
  clearanceBackwardsInmm: null
};

export const emptyComplexAttributes = {
  heatOutputInW: null,
  pressureEquipment: "",
  noiseIndBA: null,
  noiseSource: "",
  noiseSourceDetails: "",
  positioningNote: "",
  positioning: "",
  vibrationSensitive: ""
};

export const emptyMasterData = {
  equipmentCategory: "",
  manufacturer: "",
  equipmentModel: "",
  variant: "",
  displayImage: "",
  isActive: true,
  weightInkg: null,
  heightInmm: null,
  lengthInmm: null,
  depthInmm: null,
  clearanceLeftInmm: null,
  clearanceRightInmm: null,
  clearanceHeightInmm: null,
  clearanceToFrontInmm: null,
  clearanceBackwardsInmm: null,
  heatOutputInW: null,
  pressureEquipment: "",
  noiseIndBA: null,
  noiseSource: "",
  noiseSourceDetails: "",
  positioningNote: "",
  positioning: "",
  vibrationSensitive: ""
};

export const emptyEditMasterData = {
  equipmentCategory: "",
  manufacturer: "",
  equipmentModel: "",
  variant: "",
  displayImage: "",
  isActive: true,
  weightInkg: null,
  heightInmm: null,
  lengthInmm: null,
  depthInmm: null,
  clearanceLeftInmm: null,
  clearanceRightInmm: null,
  clearanceHeightInmm: null,
  clearanceToFrontInmm: null,
  clearanceBackwardsInmm: null,
  heatOutputInW: null,
  pressureEquipment: "",
  noiseIndBA: null,
  noiseSource: "",
  noiseSourceDetails: "",
  positioningNote: "",
  positioning: "",
  vibrationSensitive: ""
};

export const MasterDataFieldTextFilter = [DATA_MODEL_TABLE?.displayImage?.key];

export const masterDataFieldTextareaFilter = [
  DATA_MODEL_TABLE?.noiseSourceDetails?.key,
  DATA_MODEL_TABLE?.positioningNote?.key
];

export const MasterDataFieldSetFilter = [
  DATA_MODEL_TABLE?.equipmentCategory?.key,
  DATA_MODEL_TABLE?.manufacturer?.key,
  DATA_MODEL_TABLE?.equipmentModel?.key,
  DATA_MODEL_TABLE?.isActive?.key,
  DATA_MODEL_TABLE?.variant?.key,
  DATA_MODEL_TABLE?.pressureEquipment?.key,
  DATA_MODEL_TABLE?.noiseSource?.key,
  DATA_MODEL_TABLE?.positioning?.key,
  DATA_MODEL_TABLE?.vibrationSensitive?.key
];

export const masterDataTableColumn = () => [
  {
    key: DATA_MODEL_TABLE.equipmentCategory.key,
    val: DATA_MODEL_TABLE.equipmentCategory.value,
    order: 1,
    show: true,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.manufacturer.key,
    val: DATA_MODEL_TABLE.manufacturer.value,
    order: 2,
    show: true,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.equipmentModel.key,
    val: DATA_MODEL_TABLE.equipmentModel.value,
    order: 3,
    show: true,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.variant.key,
    val: DATA_MODEL_TABLE.variant.value,
    order: 4,
    show: true,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.displayImage.key,
    val: DATA_MODEL_TABLE.displayImage.value,
    order: 5,
    show: true,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.isActive.key,
    val: DATA_MODEL_TABLE.isActive.value,
    order: 6,
    show: true,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.weightInkg.key,
    val: DATA_MODEL_TABLE.weightInkg.value,
    order: 7,
    show: false,
    sortStatus: true,
    imperialField: DATA_MODEL_TABLE?.weightInlbs?.key
  },
  {
    key: DATA_MODEL_TABLE.heightInmm.key,
    val: DATA_MODEL_TABLE.heightInmm.value,
    order: 8,
    show: false,
    sortStatus: true,
    imperialField: DATA_MODEL_TABLE?.heightInInch?.key
  },
  {
    key: DATA_MODEL_TABLE.lengthInmm.key,
    val: DATA_MODEL_TABLE.lengthInmm.value,
    order: 9,
    show: false,
    sortStatus: true,
    imperialField: DATA_MODEL_TABLE?.lengthIninch?.key
  },
  {
    key: DATA_MODEL_TABLE.depthInmm.key,
    val: DATA_MODEL_TABLE.depthInmm.value,
    order: 10,
    show: false,
    sortStatus: true,
    imperialField: DATA_MODEL_TABLE?.depthIninch?.key
  },
  {
    key: DATA_MODEL_TABLE.clearanceLeftInmm.key,
    val: DATA_MODEL_TABLE.clearanceLeftInmm.value,
    order: 11,
    show: false,
    sortStatus: true,
    imperialField: DATA_MODEL_TABLE?.clearanceLeftIninch?.key
  },
  {
    key: DATA_MODEL_TABLE.clearanceRightInmm.key,
    val: DATA_MODEL_TABLE.clearanceRightInmm.value,
    order: 12,
    show: false,
    sortStatus: true,
    imperialField: DATA_MODEL_TABLE?.clearanceRightInInch?.key
  },
  {
    key: DATA_MODEL_TABLE.clearanceHeightInmm.key,
    val: DATA_MODEL_TABLE.clearanceHeightInmm.value,
    order: 13,
    show: false,
    sortStatus: true,
    imperialField: DATA_MODEL_TABLE?.clearanceHeightIninch?.key
  },
  {
    key: DATA_MODEL_TABLE.clearanceToFrontInmm.key,
    val: DATA_MODEL_TABLE.clearanceToFrontInmm.value,
    order: 14,
    show: false,
    sortStatus: true,
    imperialField: DATA_MODEL_TABLE?.clearanceToFrontIninch?.key
  },
  {
    key: DATA_MODEL_TABLE.clearanceBackwardsInmm.key,
    val: DATA_MODEL_TABLE.clearanceBackwardsInmm.value,
    order: 15,
    show: false,
    sortStatus: true,
    imperialField: DATA_MODEL_TABLE?.clearanceBackwardsIninch?.key
  },
  {
    key: DATA_MODEL_TABLE.heatOutputInW.key,
    val: DATA_MODEL_TABLE.heatOutputInW.value,
    order: 16,
    show: false,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.pressureEquipment.key,
    val: DATA_MODEL_TABLE.pressureEquipment.value,
    order: 17,
    show: false,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.noiseIndBA.key,
    val: DATA_MODEL_TABLE.noiseIndBA.value,
    order: 18,
    show: false,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.noiseSource.key,
    val: DATA_MODEL_TABLE.noiseSource.value,
    order: 19,
    show: false,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.noiseSourceDetails.key,
    val: DATA_MODEL_TABLE.noiseSourceDetails.value,
    order: 20,
    show: false,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.positioningNote.key,
    val: DATA_MODEL_TABLE.positioningNote.value,
    order: 21,
    show: false,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.positioning.key,
    val: DATA_MODEL_TABLE.positioning.value,
    order: 22,
    show: false,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.vibrationSensitive.key,
    val: DATA_MODEL_TABLE.vibrationSensitive.value,
    order: 23,
    show: false,
    sortStatus: true
  }
];

export const MASTER_DATA_DEFAULT_COLUMNS = {
  displayName: "",
  displayDefinition: {
    showColumns: [
      {
        key: DATA_MODEL_TABLE.equipmentCategory.key,
        val: DATA_MODEL_TABLE.equipmentCategory.value,
        order: 1,
        show: true,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.manufacturer.key,
        val: DATA_MODEL_TABLE.manufacturer.value,
        order: 2,
        show: true,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.equipmentModel.key,
        val: DATA_MODEL_TABLE.equipmentModel.value,
        order: 3,
        show: true,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.variant.key,
        val: DATA_MODEL_TABLE.variant.value,
        order: 4,
        show: true,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.displayImage.key,
        val: DATA_MODEL_TABLE.displayImage.value,
        order: 5,
        show: true,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.isActive.key,
        val: DATA_MODEL_TABLE.isActive.value,
        order: 6,
        show: true,
        sortStatus: true
      }
    ],
    hideColumns: [
      {
        key: DATA_MODEL_TABLE.weightInkg.key,
        val: DATA_MODEL_TABLE.weightInkg.value,
        order: 7,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.heightInmm.key,
        val: DATA_MODEL_TABLE.heightInmm.value,
        order: 8,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.lengthInmm.key,
        val: DATA_MODEL_TABLE.lengthInmm.value,
        order: 9,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.depthInmm.key,
        val: DATA_MODEL_TABLE.depthInmm.value,
        order: 10,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.clearanceLeftInmm.key,
        val: DATA_MODEL_TABLE.clearanceLeftInmm.value,
        order: 11,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.clearanceRightInmm.key,
        val: DATA_MODEL_TABLE.clearanceRightInmm.value,
        order: 12,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.clearanceHeightInmm.key,
        val: DATA_MODEL_TABLE.clearanceHeightInmm.value,
        order: 13,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.clearanceToFrontInmm.key,
        val: DATA_MODEL_TABLE.clearanceToFrontInmm.value,
        order: 14,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.clearanceBackwardsInmm.key,
        val: DATA_MODEL_TABLE.clearanceBackwardsInmm.value,
        order: 15,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.heatOutputInW.key,
        val: DATA_MODEL_TABLE.heatOutputInW.value,
        order: 16,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.pressureEquipment.key,
        val: DATA_MODEL_TABLE.pressureEquipment.value,
        order: 17,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.noiseIndBA.key,
        val: DATA_MODEL_TABLE.noiseIndBA.value,
        order: 18,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.noiseSource.key,
        val: DATA_MODEL_TABLE.noiseSource.value,
        order: 19,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.noiseSourceDetails.key,
        val: DATA_MODEL_TABLE.noiseSourceDetails.value,
        order: 20,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.positioningNote.key,
        val: DATA_MODEL_TABLE.positioningNote.value,
        order: 21,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.positioning.key,
        val: DATA_MODEL_TABLE.positioning.value,
        order: 22,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.vibrationSensitive.key,
        val: DATA_MODEL_TABLE.vibrationSensitive.value,
        order: 23,
        show: false,
        sortStatus: true
      }
    ]
  }
};

export const MASTER_DATA_DEFAULT_FILTER = {
  filterName: "",
  filterDefinition: {
    equipmentCategory: "",
    manufacturer: "",
    equipmentModel: "",
    variant: "",
    isActive: true,
    displayImage: "",
    weightInkg: null,
    heightInmm: null,
    lengthInmm: null,
    depthInmm: null,
    clearanceLeftInmm: null,
    clearanceRightInmm: null,
    clearanceHeightInmm: null,
    clearanceToFrontInmm: null,
    clearanceBackwardsInmm: null,
    heatOutputInW: null,
    pressureEquipment: "",
    noiseIndBA: null,
    noiseSource: "",
    noiseSourceDetails: "",
    positioningNote: "",
    positioning: "",
    vibrationSensitive: ""
  }
};
export const agGridAutoGroupfieldName = "ag-Grid-AutoColumn";

export const EQUIPMENT_REVIEW_SECTIONS = [
  {
    name: "Basic identification",
    fields: [
      DATA_MODEL_TABLE.linkedInstance.key,
      DATA_MODEL_TABLE.siteName.key,
      DATA_MODEL_TABLE.serialNumber.key,
      DATA_MODEL_TABLE.materialNumber.key,
      DATA_MODEL_TABLE.inventoryNumber.key,
      DATA_MODEL_TABLE.equipmentId.key,
      DATA_MODEL_TABLE.instrumentRUDI.key,
      DATA_MODEL_TABLE.instrumentGTIN.key
    ]
  },
  {
    name: "Equipment description",
    fields: [
      DATA_MODEL_TABLE.equipmentCategory.key,
      DATA_MODEL_TABLE?.manufacturer?.key,
      DATA_MODEL_TABLE?.equipmentModel?.key,
      DATA_MODEL_TABLE.variant.key,
      DATA_MODEL_TABLE.cluster.key,
      DATA_MODEL_TABLE.equipmentNickName.key,
      DATA_MODEL_TABLE.tags.key,
      DATA_MODEL_TABLE?.comment?.key,
      DATA_MODEL_TABLE?.network?.key
    ]
  },
  {
    name: "Laboratory and responsibility",
    fields: [
      DATA_MODEL_TABLE.belongingToGroup.key,
      DATA_MODEL_TABLE.buildingLocation.key,
      DATA_MODEL_TABLE.floor.key,
      DATA_MODEL_TABLE.room.key,
      DATA_MODEL_TABLE.responsiblePerson.key,
      DATA_MODEL_TABLE.equipmentAdministrator.key,
      DATA_MODEL_TABLE.systemOwner.key,
      DATA_MODEL_TABLE.responsibleProxy.key,
      DATA_MODEL_TABLE.functionalLocation.key,
      DATA_MODEL_TABLE.costCenter.key
    ]
  },
  {
    name: "Qualification status and maintenance",
    fields: [
      DATA_MODEL_TABLE.sop.key,
      DATA_MODEL_TABLE.csv.key,
      DATA_MODEL_TABLE.electronicRecord.key,
      DATA_MODEL_TABLE.electronicSignatures.key,
      DATA_MODEL_TABLE.dateOfNextPeriodicReview.key,
      DATA_MODEL_TABLE.maintenancePlan.key,
      DATA_MODEL_TABLE.gxpRelevant.key,
      DATA_MODEL_TABLE.qualificationStatus.key,
      DATA_MODEL_TABLE.controlledEquipmentStatus.key,
      DATA_MODEL_TABLE.dateOfLastMaintanance.key,
      DATA_MODEL_TABLE.maintenanceIntervalInDays.key,
      DATA_MODEL_TABLE.dateOfNextMaintanance.key,
      DATA_MODEL_TABLE.documentedMaintenanceDate.key,
      DATA_MODEL_TABLE.qualificationDocuments.key,
      DATA_MODEL_TABLE.systemStatus.key
    ]
  },
  {
    name: "Configuration and availability",
    fields: [
      DATA_MODEL_TABLE.softwareVersion.key,
      DATA_MODEL_TABLE.configurationBaseline.key,
      DATA_MODEL_TABLE.installedTests.key,
      DATA_MODEL_TABLE.configurationDetails.key,
      DATA_MODEL_TABLE.isBookable.key,
      DATA_MODEL_TABLE.isVisualized.key,
      DATA_MODEL_TABLE.checkInRequired.key,
      DATA_MODEL_TABLE.checkInStartPeriod.key,
      DATA_MODEL_TABLE.checkInGracePeriod.key
    ]
  }
];

export const EQUIPMENT_REVIEW_ACTIONS = {
  review: { key: "REVIEW", value: "review" },
  revert: { key: "REVERTED", value: "revert" },
  reject: { key: "REJECTED", value: "reject" },
  approved: { key: "APPROVED", value: "approved" },
  failure: { key: "FAILURE", value: "failure" },
  duplicate: { key: "DUPLICATE", value: "duplicate" },
  success: { key: "SUCCESS", value: "success" }
};

export const EQUIPMENT_STATUS_FIELD = {
  ACTIVE: "Active",
  PENDING: "Pending",
  DELETED: "Deleted"
};

export const THEME_NAME = "cobas_light";

export const CHECKLIST_STATUS_ENUM = {
  ACTIVE: "ACTIVE",
  DELETED: "DELETED"
};

export const CHECKLIST_AUDIT_TRIAL_ITEM_ACTIONS_DISPLAY_VALUES = {
  CREATE: "Created",
  DELETE: "Deleted",
  MODIFY: "Modified"
};
